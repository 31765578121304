import { ServerApi, ServerResponse } from "@sleekly-ui/ServerApi";
import { getCurrentUser, logOut } from "../login/Login";

export type WebSearchResults = {
    results: WebSearchResultEntry[]
}


export type WebSearchResultEntry = {
    title: string,
    content: string,
    url: string,
    engine: string,
    publishedDate?: string
}


export namespace WebSearch {

    export async function webSearch(query: string, scopeUrl: string, engines?: string[]): Promise<WebSearchResults> {
        if (query.length > 0) {

            const user = getCurrentUser();
            const response: ServerResponse<WebSearchResults> = await ServerApi.postJson("/api/search", {
                query,
                site: scopeUrl,
                engines: engines ?? []
            }, user?.token);

            if (response.status === 200) {
                return response.data;

            } else if (response.status === 403) {
                logOut();
            } else {
                console.log(response.status, response.statusText);
            }  
        }
    };

}