import * as React from 'react'

type Props = {
    comment: string
} & React.HTMLAttributes<HTMLDivElement>

export const ZonePlaceholder: React.FC<Props> = ({ comment, className }: Props) => {

    return <div className={`bg-neutral rounded-md p-1 flex flex-col diagonal-lines  ${className}`}>
        <div className='flex-1 flex items-center justify-center relative text-neutral-content'>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                className='absolute' stroke="currentColor">
                <line x1="0" y1="0" x2="100%" y2="100%" strokeWidth="2" />
                <line x1="100%" y1="0" x2="0" y2="100%" strokeWidth="2" />
            </svg>
            <div className='bg-neutral bg-opacity-80 z-10 text-center max-w-prose'>{comment}</div>
        </div>
        
    </div>
}