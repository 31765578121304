import * as React from 'react'
import { ProgressIndicator } from './ProgressIndicator';

type IdType = {
    id: string
}

type Props = {
    hideBorders?: boolean,
    items: IdType[];
    renderItem: (item: IdType) => React.ReactNode;
    emptyIllustration?: () => React.ReactNode;
    emptyMessage?: string;
    emptyMessageDetails?: string;
    emptyActions?: () => React.ReactNode;
    loading?: boolean;
    loadingMessage?: string
} & React.HTMLAttributes<HTMLDivElement>

export const List: React.FC<Props> = ({ hideBorders, items, emptyMessage, emptyMessageDetails, emptyActions, renderItem, emptyIllustration, loading, loadingMessage}: Props) => {

    const bordersStyle = hideBorders ? '' : 'border';
    const displayedItems = items ? items : [];
    return <div className={`flex-1 flex flex-col overflow-auto ${bordersStyle} border-neutral-700 rounded-lg p-4`}>
        {
            !loading && displayedItems.length > 0 && displayedItems.map((item, index) => (
                <div key={item.id}>{renderItem(item)}</div>
            ))
        }
        {
            !loading && displayedItems.length === 0 && <div className='flex-1 flex flex-col items-center justify-center'>
                {
                    emptyIllustration && <div className='p-8'>
                        {emptyIllustration()}
                    </div>
                }
                <div className='font-bold mb-1 text-center'>{emptyMessage}</div>
                <div className='font-thin mb-1 text-center'>{emptyMessageDetails}</div>
                {
                    emptyActions && <div className='p-2'>
                        {emptyActions()}
                    </div>
                }
            </div>
        }
        {
            loading && <div className='flex-1 flex flex-col items-center justify-center'>
                <ProgressIndicator visible={loading}></ProgressIndicator>
                {
                    loadingMessage && <div className='font-thin m-2'>{loadingMessage}</div>
                }
            </div>
        }
    </div>
}