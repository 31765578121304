import * as React from 'react'
import { ZonePlaceholder } from '@sleekly-ui/components/ZonePlaceholder'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const Technology:React.FC<Props> = ({}: Props) => {

    return <>
        <ScreenTitle>Contexte de la technologie</ScreenTitle>
        <ZonePlaceholder comment="Page d'investigation sur la technologie ADU"
            className='flex-1'></ZonePlaceholder>
    </>
}